const OKTA_Groups = {
  campaign_manager: 'LCM-Insight_Engine-Test_Campaign_Manager',
  qa: 'LCM-Insight_Engine-Test_QA_Tester',
  promoter: 'LCM-Insight_Engine-Prod_Test_Campaign_Promoter',
  drb_user: 'LCM-Insight_Engine-DRB_User',
  drb_manager: 'LCM-Insight_Engine-DRB_Manager'
};
export const environment = {
  production: false,
  appName: 'Insight Engine UI',
  ADFS_LOGIN_URL: 'https://fleet-login-external.iot.irobotapi.com/v1/adfs/login',
  ADFS_LOGOUT_URL: 'https://fleet-login.prod.iot.irobotapi.com/v1/adfs/logout',
  envName: 'stage',
  okta_groups: OKTA_Groups,
  configEndpoint: 'ruhld4ou6i',
  circuitUrl: 'https://stage-b-circuit-auth.test.iot.irobotapi.com/',
  account_num: '400116555502',
  awsRegion: 'us-east-1',
  cookieExpireTime: 60 * 60,
  currentVersion: '@SOURCE_CODE_VERSION@',
  stage: 'stage',
  apiAccountStage: 'preprod',
  client_id: 'r4unkeb60t12t2ifju0ku3kqi',
  cognitoTokenURL: 'https://insights-stage.auth.us-east-1.amazoncognito.com/oauth2/token',
  loginUrl:
    'https://insight-engine-stage.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=OKTA&redirect_uri=https://engine.app.insights-test.data.irobotapi.com/callback&response_type=CODE&client_id=r4unkeb60t12t2ifju0ku3kqi&scope=email openid profile',
  redirectURL: 'https://engine.app.insights-test.data.irobotapi.com/callback',
  bundle_names: ['marketing', 'marketing_translations']
  //logout: 'https://hkc-insights-stage.auth.us-east-1.amazoncognito.com/logout?' + 'client_id=r4unkeb60t12t2ifju0ku3kqi&' + 'response_type=code&' + 'scope=openid+email' + '&redirect_uri=http://localhost:4200/signout'
  //logout: 'https://hkc-insights-stage.auth.us-east-1.amazoncognito.com/logout?' + 'client_id=r4unkeb60t12t2ifju0ku3kqi&' + 'response_type=code&' + 'scope=openid+email' + '&redirect_uri=http://localhost:4200/signout'
};

export const config = {
  aws_project_region: 'us-east-1',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_lC4yhEA52',
  aws_user_pools_web_client_id: 'r4unkeb60t12t2ifju0ku3kqi',
  aws_cognito_identity_pool_id: 'us-east-1:de3268e5-0531-4210-9928-5c0c0e79b4a1',
  API: {
    endpoints: [
      {
        name: 'development',
        endpoint: 'https://s358d0rao0.execute-api.us-east-1.amazonaws.com',
        region: 'us-east-1'
      }
    ]
  },
  oauth: {
    domain: 'insight-engine-stage.auth.us-east-1.amazoncognito.com',
    scope: ['email', 'openid', 'profile'],
    redirectSignIn: 'https://engine.app.insights-test.data.irobotapi.com/callback',
    redirectSignOut: 'https://engine.app.insights-test.data.irobotapi.com/signout',
    responseType: 'code'
  },
  federationTarget: 'COGNITO_USER_POOLS'
};
